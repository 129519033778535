.benefits {
  :global {
    .ant-cascader-menus .ant-cascader-menu {
      height: 300px;
    }
  }
}

.benefitsLocal {
  :global {
    .ant-cascader-menus .ant-cascader-menu {
      height: 357px;
    }
  }
}
