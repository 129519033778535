/* stylelint-disable font-family-no-missing-generic-family-keyword */

.h5CompanyHeader {
  /* WEB/Heading/H5 */
  font-family: "SF Pro Display";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 140% */
  color: #000;

  .findTxt {
    font-weight: 700;
  }

  .idealTxt {
    /* WEB/Heading/H5 */
    font-weight: 700;
    color: #1c70ee;
  }
}

.footer {
  display: flex;
  justify-content: center;
  margin: 40px 0;

  .viewMoreBtn {
    display: flex;
    gap: 4px;
    align-items: center;
    font-family: "SF Pro Display";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 157.143% */
    color: var(--text-icon-07121212, #111212);
    text-align: center;
  }
}

.filters {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  margin-top: 16px;

  /* H5/Heading/Subtitle2-medium */
  font-family: "SF Pro Display";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: var(--neutral-color-text-05515151, #515151);
  text-align: center;

  &.hasFilterNumber {
    color: var(--brand-color-050066-ff, #1c70ee);

    path {
      stroke: currentcolor;
    }
  }
}

.filterNumber {
  min-width: 20px;
  height: 16px;
  font-family: "SF Pro Display";
  font-size: 12px;
  font-style: normal;
  font-weight: 600; /* 183.333% */
  line-height: 16px;
  color: var(--brand-color-050066-ff, #1c70ee);
  text-align: center;
  background: rgba(0, 102, 255, 0.1);
  border-radius: 10px;
}
