@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

.loadingWrapper {
  display: flex;
  gap: 8px;
  align-items: center;
  color: #1c70ee;

  svg {
    animation: spin 0.5s linear infinite;
  }
}
