.searchCardH5Root {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 20px;
  background: #fff;
  border-radius: 0 0 16px 16px;

  /* Normal Shadow Styles/Normal Shadow-md */
  box-shadow: 0 4px 6px 0 rgba(162, 183, 203, 0.1);
}

.inputH5Root {
  overflow: hidden;

  // height: 56px;
  // padding: 8px 10px 8px 7px;
  background: rgba(255, 255, 255, 0.7);
  border: 1.2px solid #1c70ee;
  border-radius: 12px;

  /* Brand Shadow Style/Brand Shadow-xl */
  box-shadow: 0 8px 10px -3px rgba(0, 102, 255, 0.1);

  :global {
    .ant-input-wrapper {
      height: 56px !important;
      padding-right: 8px;
      padding-left: 8px;
      border: none !important;
      box-shadow: none !important;
    }

    .ant-input {
      width: calc(100% / 0.875);
      height: 56px !important;
      padding: 0 12px;
      font-size: 16px !important;
      border: none !important;
      box-shadow: none !important;
      transform: scale(0.875);
      transform-origin: left;
    }

    .ant-input,
    .ant-input::placeholder {
      // font-family: "SF Pro Display" !important;
      // font-size: 14px !important;
      font-weight: 500 !important;
      color: #000 !important;
    }

    .ant-input-group-addon {
      padding: 0 !important;
      margin-right: 10px !important;
      background-color: #fff !important;
      border: none !important;
    }
  }

  .searchBtn {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 16px 8px;
    cursor: pointer;
    background: var(--brand-color-primary-050066-ff-key, #1c70ee);
    border-radius: 10px;
  }
}

.popupRoot {
  padding: 12px;

  :global {
    .ant-select-item {
      padding: 0 !important;
      background-color: #fff !important;
    }
  }

  .popupItem {
    display: flex;
    gap: 4px;
    align-items: center;
    align-self: stretch;
    height: 44px;
    padding: 6px 8px;
  }

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background: #fff;
    border: 0.267px solid var(--line-border-02-f-4-f-5-f-6-secondary, #f4f5f6);
    border-radius: 4px;
  }
}
