/* stylelint-disable max-nesting-depth */
/* stylelint-disable font-family-no-missing-generic-family-keyword */
/* stylelint-disable custom-property-pattern */
.searchCardH5Root {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 20px;
  background: #fff;
  border-radius: 0 0 16px 16px;

  /* Normal Shadow Styles/Normal Shadow-md */
  box-shadow: 0 4px 6px 0 rgba(162, 183, 203, 0.1);
}

.inputH5Root {
  overflow: hidden;

  /* WEB/Heading/Subtitle3-medium */
  font-family: "SF Pro Display";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  color:  #000;
  background: rgba(255, 255, 255, 0.7);
  border: 1.2px solid #1c70ee;
  border-radius: 12px;

  /* Brand Shadow Style/Brand Shadow-xl */
  // box-shadow: 0 8px 10px -3px rgba(0, 102, 255, 0.1);

  :global {
    .ant-input-wrapper {
      height: 56px !important;
      padding-right: 10px;
      border: none !important;
      box-shadow: none !important;
    }

    .ant-input {
      height: 56px !important;
      padding-left: 20px !important;
      border: none !important;
      box-shadow: none !important;
    }

    .ant-input,
    .ant-input::placeholder {
      /* WEB/Heading/Subtitle3-medium */
      // font-family: "SF Pro Display" !important;
      font-size: 16px !important;
      font-style: normal !important;
      font-weight: 500 !important;
      line-height: 24px !important; /* 150% */
      color:  #000 !important;
    }

    .ant-input-group-addon {
      padding: 0 !important;
      margin-right: 10px !important;
      background-color: #fff !important;
      border: none !important;
    }
  }
}

.autoCompleteRoot {
  position: relative;
  display: flex;
  width: 924px;
  margin: 0 auto;
  background-color: #1c70ee;

  // backdrop-filter: blur(6px);
  border-radius: 12px;
  box-shadow: 0 4px 12px 0 rgba(0, 102, 255, 0.03);

  &:hover {
    box-shadow:
      0 0 0 3px rgba(28, 112, 238, 0.1),
      // 0 12px 24px 0 rgba(0, 102, 255, 0.15)
;

    :global {
      .ant-select-selection-search {
        .ant-input,
        .ant-input::placeholder {
          color: #1c70ee !important;
        }
      }
    }
  }
}

.searchBtn {
  display: inline-flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  height: 56px;
  padding: 16px 34px 16px 16px;

  // padding-left: 100px;
  cursor: pointer;
  background: var(--brand-color-primary-050066-ff-key, #1c70ee);
  border-radius: 10px;

  .text {
    /* WEB/Caption/Caption-Bold */
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 133.333% */
    color: #fff;
    white-space: nowrap;
  }
}

.popupRoot {
  padding: 12px;

  :global {
    .ant-select-item {
      padding: 0 !important;
      background-color: #fff !important;
    }
  }

  .popupItem {
    display: flex;
    gap: 4px;
    align-items: center;
    align-self: stretch;
    height: 44px;
    padding: 6px 8px;
    cursor: pointer;
  }

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background: #fff;
    border: 0.267px solid var(--line-border-02-f-4-f-5-f-6-secondary, #f4f5f6);
    border-radius: 4px;
  }
}
