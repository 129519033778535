.paginationRoot {
  display: flex;
  gap: 4px;
  justify-content: center;
}

.paginationItem {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;

  // padding: 13px 11px;
  cursor: pointer;
  background: #fff;
  border-radius: 8px;

  &.rightBtn {
    svg {
      transform: rotate(180deg);
    }
  }

  &.disabled {
    cursor: not-allowed;

    svg {
      opacity: 0.2;
    }
  }
}
