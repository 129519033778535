.emptyNode {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  /* WEB/Heading/Subtitle3-medium */
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 175% */
  color: var(--global-neutral-14202021, #202021);
  text-align: center;
}
