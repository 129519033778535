/* stylelint-disable font-family-no-missing-generic-family-keyword */
.pcCommButton {
  position: relative;
  box-sizing: border-box;
  display: inline-flex;
  gap: 6px;
  align-items: center;
  justify-content: center;
  padding: 0 32px;
  overflow: hidden;
  font-family: "SF Pro Display";
  font-style: normal;
  cursor: pointer;
}

/* stylelint-disable-next-line keyframes-name-pattern */
@keyframes loadingCircle {
  100% {
    transform: rotate(360deg);
  }
}

.loadingWrapper {
  display: inline-flex;
  animation: loadingCircle 1s infinite linear;

  svg {
    width: 1em;
    height: 1em;

    // fill: currentcolor;
  }
}

.size48 {
  height: 48px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px; /* 150% */
  border-radius: 12px;
}

.size44 {
  height: 44px;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  border-radius: 12px;
}

.size40 {
  height: 40px;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  border-radius: 10px;
}

.size36 {
  height: 36px;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  border-radius: 8px;
}

.size32 {
  height: 32px;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  border-radius: 8px;
}

.loadingBtn {
  pointer-events: none;

  // user-select: none;
  opacity: 0.7;
}

.primaryBtn {
  color: var(--neutral-color-text-01-ffffff, #fff);
  background: var(--brand-color-primary-050066-ff-key, #1c70ee);

  // &:hover {
  //   background: var(--brand-color-primary-060055-d-4, #0055d4);
  // }

  &.disabled {
    pointer-events: none;
    opacity: 0.25;
  }

  &:not(&.disabled, .loadingBtn):hover {
    background: var(--brand-color-primary-060055-d-4, #0055d4);
  }
}

.primaryOutlineBtn {
  color: var(--brand-color-primary-050066-ff-key, #1c70ee);
  border: 1px solid var(--brand-color-primary-050066-ff-key, #1c70ee);

  &.size48,
  &.size44,
  &.size40 {
    border-width: 1.2px;
  }

  &:not(&.disabled, .loadingBtn):hover {
    color:  #0055d4;
    border-color:  #0055d4;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.25;
  }
}

.blackBtn {
  color:  #fff;
  /* stylelint-disable-next-line custom-property-pattern */
  background:  #000;

  &.disabled {
    pointer-events: none;
    opacity: 0.25;
  }

  &:not(&.disabled, .loadingBtn):hover {
    background:  rgba(0, 0, 0, 0.7);
  }
}

.whiteBtn {
  color: #000;
  background: #fff;
  border: 1px solid transparent;

  &.disabled {
    color:  rgba(0, 0, 0, 0.1);
    pointer-events: none;
    background:  #f8f9fa;
  }

  &:not(&.disabled, .loadingBtn):hover {
    border: 1px solid  rgba(0, 102, 255, 0.5);
    box-shadow: 0 0 0 2px rgba(0, 102, 255, 0.1);
  }
}

.grayBtn {
  color:  #000;
  background:  #f8f9fa;

  &.disabled {
    color: var(--global-alpha-black-black-10, rgba(0, 0, 0, 0.1));
    pointer-events: none;
    background: var(--background-neutral-bg-01-f-8-f-9-fa-primary, #f8f9fa);
  }

  &:not(&.disabled, .loadingBtn):hover {
    background: var(--background-neutral-bg-02-f-3-f-5-f-6-secondary, #f3f5f6);
  }
}

.whiteOpacityBtn {
  /* stylelint-disable-next-line custom-property-pattern */
  color: var(--Global-Alpha-Black-Black, #000);
  background-color: rgba(255, 255, 255, 0.7);
  border: 1px solid transparent;

  // &::before {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   z-index: -1;
  //   width: calc(100% + 2px);
  //   height: calc(100% + 2px);
  //   content: " ";
  //   background: rgba(255, 255, 255, 0.7);
  // }

  &.disabled {
    color: var(--global-alpha-black-black-10, rgba(0, 0, 0, 0.1));
    pointer-events: none;
    background: var(--background-neutral-bg-01-f-8-f-9-fa-primary, #f8f9fa);
  }

  &:not(&.disabled, .loadingBtn):hover {
    border-color: var(--global-alpha-brand-brand-50, rgba(0, 102, 255, 0.5));
    box-shadow: 0 0 0 2px rgba(0, 102, 255, 0.1);
  }
}

.primaryTextBtn {
  color: var(--brand-color-primary-050066-ff-key, #1c70ee);

  &.disabled {
    pointer-events: none;
    opacity: 0.25;
  }

  &:not(&.disabled, .loadingBtn):hover {
    color: var(--brand-color-primary-060055-d-4, #0055d4);
  }
}

.whiteBoxShadowBtn {
  color: var(--text-icon-08-black, #000);
  background: #fff;
  border: 1.2px solid var(--line-border-04-e-2-e-3-e-4-quaternary, #dbdfe4);

  &.size48,
  &.size44 {
    /* Normal Shadow Styles/Normal Shadow-md */
    box-shadow: 0 4px 6px 0 rgba(162, 183, 203, 0.1);
  }

  &.size40,
  &.size36,
  &.size32 {
    box-shadow: 0 1px 3px 0 rgba(195, 199, 203, 0.1);
  }

  &.disabled {
    color: var(--global-alpha-black-black-10, rgba(0, 0, 0, 0.1));
    pointer-events: none;
    background: var(--background-neutral-bg-01-f-8-f-9-fa-primary, #f8f9fa);
    box-shadow: none;
  }

  &:not(&.disabled, .loadingBtn):hover {
    border-color: var(--global-alpha-brand-brand-50, rgba(0, 102, 255, 0.5));
    box-shadow: 0 0 0 2px rgba(0, 102, 255, 0.1);
  }
}
