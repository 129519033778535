/* stylelint-disable font-family-no-missing-generic-family-keyword */

.topHeaderText {
  min-width: 1136px;
  margin-top: 16px;
  margin-bottom: 28px;
  font-family: "SF Pro Display";
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 38px;
  color: #000;
  text-align: center;

  :global {
    .coloredText {
      font-weight: 700;
      color: #1c70ee;
    }
  }
}

.mainHeaderText {
  /* WEB/Heading/H3 */
  font-family: "SF Pro Display";
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px; /* 126.667% */
  color: #000;
}

.searchContainer {
  padding: 12px 0;

  //   // background-color: rgb(113, 160, 241);

  //   @media screen and (min-width: 1440px) {
  //     margin: 0 48px;
  //     border-radius: 16px;
  //   }
}

.companiesPagination {
  padding: 20px 0;
}

.emptyNode {
  display: flex;
  flex-direction: column;

  // gap: 24px;
  align-items: center;
  justify-content: center;
  width: 100%;

  /* WEB/Heading/Subtitle3-medium */
  font-family: "SF Pro Display";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 175% */
  color: var(--global-neutral-14202021, #202021);
  text-align: center;
}
