/* stylelint-disable no-descending-specificity */
.searchBox {
  :global {
    .wrapperSelectMut {
      .ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
        background: #fff;
        border: 1px solid #ebeef0;
        box-shadow: 0 4px 6px 0 rgba(162, 183, 203, 0.1);
      }
    }
  }

  .resetBtn {
    border: 1px solid #ebeef0;
  }
}

.sortFilterWrapperForCompanies {
  :global {
    .ant-select-selector {
      box-shadow: 0 4px 6px 0 rgba(162, 183, 203, 0.1) !important;
    }

    .selectLabel {
      max-width: 220px !important;
    }
  }
}
