/* stylelint-disable font-family-no-missing-generic-family-keyword */
.searchPanelH5Root {
  :global {
    .ant-drawer-header {
      display: none !important;
    }

    .ant-drawer-body {
      padding: 0 !important;
    }
  }
}

.customerContent {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}

.header {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  height: 44px;
  padding: 8px 20px;
  background: #fff;

  .headerText {
    /* H5/Heading/Subtitle1-semibold */
    font-family: "SF Pro Display";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 23px; /* 143.75% */
    color: var(--text-icon-07121212, #111212);
    text-align: center;
  }

  .closeWrapper {
    position: absolute;
    right: 20px;
    display: flex;
  }
}

.content {
  flex: 1;
  overflow: auto;
}

.footerRoot {
  display: flex;
  gap: 16px;
  padding: 12px 20px;
  border-top: 1px solid var(--black-200-f-6-f-6-f-6-bg, #f6f6f6);

  // background: var(--Global-Alpha-White-White, #FFF);

  .submitBtn {
    display: flex;
    flex: 1 0 0;
    gap: 6px;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 12px 32px;

    /* WEB/Button/Default-Semibold */
    font-family: "SF Pro Display";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 157.143% */
    color: var(--neutral-color-text-01-ffffff, #fff);
    background: #1c70ee;
    border-radius: 10px;
  }

  .cancelBtn {
    box-sizing: border-box;
    display: flex;
    flex: 1 0 0;
    gap: 6px;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 12px 32px;

    /* WEB/Button/Default-Semibold */
    font-family: "SF Pro Display";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 157.143% */
    color: var(--brand-color-primary-050066-ff-key, #1c70ee);
    border: 1.2px solid #1c70ee;
    border-radius: 10px;
  }
}

.collapseWrapper {
  background-color: #f8f9fa !important;

  :global {
    .ant-collapse-header {
      padding: 0 !important;
    }

    .ant-collapse-content-box {
      padding: 0 !important;
    }

    .arrowWrapper {
      width: 16px;
      height: 16px;
    }

    /* stylelint-disable-next-line selector-max-class */
    .ant-collapse-item-active > .ant-collapse-header > .ant-collapse-header-text > div > .arrowWrapper {
      transform: rotate(180deg);
    }
  }

  .customerBaseHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;
  }

  .customerMainHeader {
    /* H5/Caption/Caption-Bold */
    font-family: "SF Pro Display";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 137.5% */
    color: var(--text-icon-07121212, #111212);
    text-align: center;
    background: #fff;
  }

  .customerSecondaryHeader {
    /* H5/Heading/Subtitle2-medium */
    font-family: "SF Pro Display";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    color: var(--text-icon-07121212, #111212);
    text-align: center;
  }

  .badge {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 16px;
    max-height: 16px;
    padding: 0 4px;
    margin: 0 8px 0 auto;
    font-size: 12px;
    color: #fff;
    background: #1c70ee;
    border-radius: 10px;
  }
}

.tagWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 5px 20px;
}

.tagItem {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  padding: 6px 10px;

  /* WEB/Heading/Subtitle4-medium */
  font-family: "SF Pro Display";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  color: var(--text-icon-063-b-3-c-3-c, #3b3c3c);
  background: #fff;
  border: 1.2px solid var(--line-border-03-eeeff-0-tertiary, #ebeef0);
  border-radius: 8px;

  &.checked {
    color: #1c70ee;
    border-color: #1c70ee;
  }
}

.contWrapper {
  opacity: 1;
}
