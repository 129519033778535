.contentWrapper {
  position: relative;
  min-width: 1104px;
  margin: 0 48px;
  max-width: 1344px;

  @media screen and (min-width: 1440px) {
    // max-width: 1310px;
    margin: 0 auto;
  }

  // @media screen and (min-width: 1920px) {
  //   width: 1624px;
  // }
}
